import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_LOCATION } from '../../config/setup/setup';
import { trackPage } from '../../helpers/analytics';
import { getLocationShortName } from '../../helpers/location';
import { sanitiseText } from '../../helpers/sanitiseText';
import ThemeContext from '../../providers/ThemeProvider';
import TrustBox from '../deal/TrustBox';
import ListicleDealBlock from './ListicleDealBlock';

const ListicleGuideContainer = ({ listicleDeals, text }) => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const location = useSelector((state) => state.locations.location);
  const locationShortName = getLocationShortName(location);
  const pageProps = {
    location: locationShortName,
    siteLocation:
      location && location.name
        ? location.name
        : DEFAULT_LOCATION[process.env.NEXT_PUBLIC_SITE || 'wowcher'].name,
  };
  const pageName = router?.query?.slug?.replace(/.html/gi, '') || '';
  const sanitisedText = sanitiseText(text);
  const parsedText =
    listicleDeals && typeof listicleDeals[Symbol.iterator] === 'function'
      ? parse(sanitisedText.toString(), {
          // eslint-disable-next-line consistent-return
          replace(domNode) {
            for (const listicle of listicleDeals) {
              for (const [key, deals] of Object.entries(listicle)) {
                // replace dom element with id matching deals list key with those deals
                if (domNode.attribs && domNode.attribs.id === key) {
                  return <ListicleDealBlock deals={deals} />;
                }
              }
            }
          },
        })
      : sanitisedText;

  useEffect(() => {
    trackPage(pageProps, pageName);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      {text && (
        <div className="listicle" id="seo-content-container">
          {parsedText}
        </div>
      )}

      <style jsx>{`
        :global(html) {
          scroll-padding-top: 15rem;
        }
        .listicle {
          padding: 25px;
          background-color: ${theme.commoncolors.white};
          border-radius: 10px;
          margin-bottom: 0;
          padding-top: 0px;
        }
        .listicle :global(.content) {
          font-size: 14px;
          margin-bottom: 40px;
          margin-top: 20px;
        }
        .listicle :global(.deal-block) {
          margin-bottom: 40px;
          margin-top: 20px;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .listicle {
            padding: 25px 0;
            margin-left: 0;
            margin-right: 0;
            padding-top: 0px;
          }
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .listicle {
            padding: 25px;
            margin-left: 0;
            margin-right: 0;
            padding-top: 0px;
          }
          .listicle :global(.content) {
            margin-bottom: 30px;
            margin-top: 20px;
          }
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .listicle :global(.content) {
            font-size: 15px;
            margin-bottom: 40px;
            margin-top: 30px;
          }
        }
        @media (min-width: ${theme.breakpoints.xlUp}) {
          .listicle :global(.content) {
            font-size: 16px;
          }
        }

        .listicle :global(.content .row) {
          margin-left: 0;
          margin-right: 0;
        }

        .listicle :global(.content .page-links) {
          box-shadow: rgba(0, 0, 0, 0.4) 0 2px 5px;
          margin-bottom: 20px;
          padding: 10px;
        }
        .listicle :global(.content .page-link) {
          color: black;
          font-weight: bold;
          text-decoration: none;
        }
        @media (min-width: ${theme.breakpoints.smUp}) {
          .listicle :global(.content .page-link) {
            text-decoration: underline;
          }
        }
        .listicle :global(.content .page-rule) {
          border-bottom: 1px solid #cccccc;
          margin-bottom: 40px;
          margin-top: 20px;
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .listicle :global(.content .page-rule) {
            margin-bottom: 60px;
            margin-top: 30px;
          }
        }
        .listicle :global(.view-all-link) {
          display: inline-block;
          margin: 0;
          font-family: inherit;
          line-height: 18px;
          cursor: pointer;
          background-color: #5300b3;
          text-align: center;
          color: #ffffff;
          font-weight: bold;
          font-size: 18px;
          border-radius: 60px;
          border: none;
          text-decoration: none;
          text-transform: uppercase;
          outline: none;
          min-height: 40px;
          padding: 12px 20px;
        }
        .listicle :global(.content .round-img) {
          border-radius: 50px;
          display: inline;
          float: left;
          height: 50px;
          margin-right: 15px;
          width: 50px;
        }
        .listicle :global(.content h1) {
          color: black;
          font-size: 22px;
          text-align: left;
          line-height: 30px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .listicle :global(.content h1) {
            font-size: 26px;
            line-height: 36px;
          }
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .listicle :global(.content h1) {
            font-size: 30px;
            line-height: 40px;
          }
        }
        .listicle :global(.content h2),
        .listicle :global(.content h3),
        .listicle :global(.content h4),
        .listicle :global(.content h5),
        .listicle :global(.content h6) {
          font-weight: normal;
          margin-bottom: 20px;
          margin-top: 0;
        }
        .listicle :global(.content h2) {
          color: ${theme.colors.primaryonwhite};
          font-weight: bold;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .listicle :global(.content h2) {
            font-size: 26px;
            line-height: 36px;
          }
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .listicle :global(.content h2) {
            font-size: 30px;
            line-height: 40px;
          }
        }
        .listicle :global(.content h3) {
          font-size: 22px;
          line-height: 22px;
          margin-bottom: 15px;
        }
        .listicle :global(.content h4) {
          font-weight: bold;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 15px;
        }
        .listicle :global(.content h5) {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 15px;
        }
        .listicle :global(.content h6) {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 15px;
        }
        .listicle :global(.content .heading2-size-xxl) {
          float: left;
          font-size: 80px;
          line-height: 80px;
        }

        .listicle :global(.content .heading2-size-md) {
          font-size: 20px;
          line-height: 20px;
        }
        .listicle :global(.content .heading2-size-lg) {
          font-size: 30px;
          line-height: 30px;
        }
        .listicle :global(.content ul) {
          margin: 0;
          padding: 0 0 0 20px;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .listicle :global(.content ul) {
            columns: 2;
          }
        }
        .listicle :global(.content li) {
          margin-bottom: 4px;
          position: relative;
        }
        .listicle :global(.content li::marker) {
          color: ${theme.colors.primary};
          font-size: 1.2em;
          line-height: 1em;
        }

        .listicle :global(.content a) {
          color: ${theme.colors.primary};
          text-decoration: underline;
        }
        .listicle :global(.content p) {
          margin-bottom: 20px;
        }
      `}</style>
    </>
  );
};

ListicleGuideContainer.propTypes = {
  listicleDeals: PropTypes.array,
  text: PropTypes.string,
};

export default ListicleGuideContainer;
